import AlarmAudio from '@/assets/audios/timeout-alarm.wav'

let alarmAudio = new Audio(AlarmAudio)
let playing = false
let retryCount = 0
const MAX_RETRY = 5
let loopMode = false

// 预加载音频
const preloadAudio = () => {
  alarmAudio.load()
  alarmAudio.preload = 'auto'
}

// 错误重试机制
const retryPlay = () => {
  if (retryCount < MAX_RETRY) {
    retryCount++
    console.log(`音频播放重试第 ${retryCount} 次`)
    setTimeout(() => {
      alarmAudio.play().catch((err) => {
        console.log('重试播放出错：', err)
        retryPlay()
      })
    }, 1000) // 1秒后重试
  } else {
    console.error('音频播放失败，已达到最大重试次数')
    retryCount = 0
    playing = false
  }
}

alarmAudio.addEventListener('ended', () => {
  if (loopMode) {
    alarmAudio.currentTime = 0
    alarmAudio.play().catch((err) => {
      console.log('循环播放出错：', err)
      retryPlay()
    })
  } else {
    playing = false
  }
})

alarmAudio.addEventListener('error', (e) => {
  console.error('音频加载失败:', e)
  retryPlay()
})

// 初始预加载
preloadAudio()

export const alarm = {
  play() {
    if (playing) return
    retryCount = 0 // 重置重试次数
    alarmAudio.play().catch((err) => {
      console.log('音频播放出错：', err)
      retryPlay()
    })
  },

  stop() {
    alarmAudio.pause()
    alarmAudio.currentTime = 0
    playing = false
    loopMode = false
    retryCount = 0
  },

  setVolume(volume) {
    alarmAudio.volume = Math.max(0, Math.min(1, volume))
  },

  // 设置循环播放
  setLoop(enable) {
    loopMode = enable
    alarmAudio.loop = enable
  },

  // 手动预加载
  preload() {
    preloadAudio()
  },

  // 获取当前播放状态
  isPlaying() {
    return playing
  }
}
