import store from '@/store'
import router from '@/router'
import http from '@/common/https'

// 创建单例
let instance = null
class BaseSocket {
  constructor(url) {
    this.url = url || ''
    this.socket = null
    this.heartbeatInterval = 3000 // 心跳间隔
    this.heartbeatMsg = "ping" // 发送心跳文本
    this.heartbeatTimer = null // 心跳定时器
    this.authorization = '' // token
    this.subscribers = new Map() // 订阅者列表
    this.errorCallbacks = new Set() // 报错回调列表
    this.reconnectAttempts = 0 // 重连次数
    this.maxReconnectAttempts = 5 // 最大重连次数
    this.disabledReconnect = false // 禁止重连，避免多次重连
    this.reconnectTimer = null // 重连定时器

    if (url) {
      this.reset()
      this.init()
    }
  }

  // 重置连接
  reset() {
    clearTimeout(this.heartbeatTimer)
    clearTimeout(this.reconnectTimer)
    this.socket && this.socket.close()
    this.reconnectAttempts = 0
  }

  // 初始化连接
  init() {
    if (this.socket) {
      return this
    }

    try {
      this.authorization = sessionStorage.getItem('token')
      this.socket = new WebSocket(`${this.url}?Authorization=${this.authorization}`)
      this.setupSocketHandlers()
    } catch (error) {
      console.error('WebSocket 连接失败:', error)
      this.handleReconnect()
    }
    return this
  }

  // 设置WebSocket事件处理
  setupSocketHandlers() {
    this.socket.onopen = () => this.open()
    this.socket.onmessage = (event) => this.message(event)
    this.socket.onclose = () => this.close()
    this.socket.onerror = (error) => this.error(error)
  }

  // 连接打开
  open() {
    this.sendHeartbeat()
    this.reconnectAttempts = 0
  }

  // 发送消息
  send(data) {
    // console.log('send--', data)
    this.socket.send(data)
  }

  // 发送心跳
  sendHeartbeat() {
    this.send(this.heartbeatMsg)
    this.checkAndRefreshToken()
    clearTimeout(this.heartbeatTimer)
    this.heartbeatTimer = setTimeout(() => {
      this.sendHeartbeat()
    }, this.heartbeatInterval)
  }

  // 处理消息
  message(event) {
    let data = event.data
    if (data && data.includes('{')) {
      try {
        data = JSON.parse(data)
        // 将数据缓存起来
        store.commit('SET_WS_DATA', data)
        // 分发消息给所有订阅者
        this.subscribers.forEach((callback) => {
          callback(data)
        })
      } catch (error) {
        console.error(`处理消息时出错:`, error)
      }
    }
  }

  // 连接关闭
  close() {
    console.log('close ws')
    clearTimeout(this.heartbeatTimer)
    this.handleReconnect()
  }

  // 连接错误
  error(error) {
    console.log('error', error)
    this.errorCallbacks.forEach(callback => {
      try {
        callback()
      } catch (err) {
        console.error('Error callback execution failed:', err)
      }
    })
    this.handleReconnect()
  }

  // 错误回调处理
  errorHandle(callback) {
    if (typeof callback === 'function') {
      this.errorCallbacks.add(callback)
    }
  }

  // 处理重连
  handleReconnect() {
    if (this.disabledReconnect || this.reconnectAttempts >= this.maxReconnectAttempts) {
      return
    }

    this.reconnectTimer = setTimeout(() => {
      this.reconnectAttempts++
      this.init()
    }, 3000 * Math.pow(2, this.reconnectAttempts))
  }

  // 订阅消息
  subscribe(id, callback) {
    if (typeof callback !== 'function') {
      throw new Error('回调必须是函数')
    }
    this.subscribers.set(id, callback)
  }

  // 取消订阅
  unsubscribe(id) {
    this.subscribers.delete(id)
  }

  // 销毁实例
  destroy() {
    this.reset()
    this.subscribers.clear()
    this.errorCallbacks.clear()
    instance = null
  }

  // 检查和刷新token
  checkAndRefreshToken() {
    const authKey = sessionStorage.getItem('authKey')
    const hrid = store.getters.loginid
    if (!authKey || !hrid) {
      router.replace('/login')
      this.destroy()
      return;
    }
    // token 过期时间
    const tokenExpire = sessionStorage.getItem('tokenExpire')
    // 时间差
    const timeDiff = tokenExpire && new Date(Number(tokenExpire)).getTime() - new Date().getTime()
    const seconds = 15000
    // 没有token过期时间或过期时间差时重新获取token
    if (!tokenExpire || timeDiff < seconds) {
      http.post('/common/getToken', {
        hrid,
        authKey,
        channel: 'PC',
      }).then(({ data }) => {
        if (data.success) {
          sessionStorage.setItem('tokenExpire', (data.content.expireTimestamp));
          sessionStorage.setItem('token', (data.content.token));
        } else {
          router.replace('/login')
          this.destroy()
        }
      });
    }
  }
}

export default {
  getInstance(url) {
    // 获取对应的ws链接
    function getWsUrl(url) {
      let urlObj = new URL(http.getBaseURL())
      urlObj.protocol = urlObj.protocol === 'http:' ? 'ws:' : 'wss:'
      return urlObj.href + url
    }
    if (!instance) {
      instance = new BaseSocket(getWsUrl(url))
    }
    return instance
  }
}